import React from "react";
import TagManager from 'react-gtm-module';
import { ThemeProvider } from "@mui/material/styles";
import config from "./config";
import theme from "./Theme";
import "./App.css";
import LandingPage from "./Pages/LandingPage";
import ForoFor from "./Pages/404";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import SuartaMainSlider from "./Pages/SliderPage";

const tagManagerArgs = {
  gtmId: config.gtagId
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <>
          <Router>
            <Routes>
              <Route path="/" exact={true} element={<LandingPage />}/>

              <Route
                path="/form"
                exact={true}
                element={<SuartaMainSlider />}
              />

              <Route path="/early-access" element={<Navigate replace to="/form" />} />

              <Route path="/request-access" element={<Navigate replace to="/form" />} />

              
              <Route path="*" exact={true} element={<ForoFor />} />
            </Routes>
          </Router>
        </>
      </div>
    </ThemeProvider>
  );
};

export default App;
