import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "./image/logo.png";

function NavbarDesktop({ navigateToEssentials }) {
  const linkStyles = {
    color: "#FFB500",
    fontWeight: "700",
    cursor: "pointer",
    fontSize: "20px",
    marginRight: "30px", // Espacio entre el botón FAQ y el nuevo botón
    textDecoration: "none", // Elimina el subrayado del enlace
    transition: "color 0.3s ease", // Agregamos una transición suave al color
  };

  const hoverStyles = {
    color: "#FFD700", // Cambiamos el color al pasar el cursor
  };

  const handleMouseEnter = (e) => {
    e.target.style.color = hoverStyles.color;
  };

  const handleMouseLeave = (e) => {
    e.target.style.color = linkStyles.color;
  };

  return (
    <div>
      <Navbar>
        <Container>
          <Navbar.Brand>
            <img src={logo} alt="navbar-desktop" style={{ width: "120px", height: "auto" }} data-robots="noindex" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text
              style={{
                ...linkStyles,
              }}
              onClick={navigateToEssentials}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              FAQ
            </Navbar.Text>
            <a
              href="https://suarte.art"
              style={{
                ...linkStyles,
              }}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Visit Suarte
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarDesktop;
