import React from "react";
import { Grid } from "@mui/material";
import SuarteSlider from "./SuartaSliderMobile";
function SuarteWorldMobile() {
  return (
    <div>
      <div className="mobile_top_world">
        <Grid container spacing={2}>
          <Grid item md={5} lg={5} xs={12} sm={12}>
            <SuarteSlider />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid item md={12} lg={12} xs={12} sm={12}>
          <img
            src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/92c9f472-d486-476e-40bc-010c62237400/w=1200"
            style={{ width: "100%" }}
            className="image_world_align_under"
            alt="suarte-world-mobile"
            data-robots="noindex"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default SuarteWorldMobile;
