import React from "react";
import { Grid } from "@mui/material";
import ResponsiveCarousel from "./ResponsiveCarosel";
import ViewInRoom from "../../components/view-in-room";

import { useState } from "react";

function DesktopViewinRoom() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [openViewInRoom, setOpenViewInRoom] = useState(false);

  return (
    <div>
      <Grid container className="positiontop_view">
        <Grid item md={12} xs={12} lg={12} sm={12}>
          <h1 className="head_view">VIEW IN ROOM</h1>
          <br />
          <p className="para_view">
            <span style={{ fontWeight: "900" }}>
              Place and visualize artworks on any wall.
            </span>{" "}
            Potential collectors can get a sense of how the piece of art looks
            like in their{" "}
            <span style={{ fontWeight: "900", color: "#FF3B58" }}>
              own space
            </span>
            , enabling them to make the best choice and improving the overall
            experience and their satisfaction.
          </p>

          <ResponsiveCarousel setCurrentSlide={setCurrentSlide} />

          <div className="container center_hero_logo">
            <button
              className="myButton_head_Desktop_Try_Mobile"
              onClick={() => setOpenViewInRoom(true)}
            >
              Try it now!
            </button>
            <p
             style={{
              color: "white",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "20px",
              }}
            >
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          md={11}
          xs={11}
          lg={11}
          sm={11}
          className="positiontop_view_image"
        >
          <img
            src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/e83e23d4-1048-4838-0c2f-b60c2cbafc00/w=1200"
            style={{ width: "100%" }}
            alt="mobile-view-in-room-3"
            data-robots="noindex"
          />
        </Grid>
      </Grid>

      <ViewInRoom
        open={openViewInRoom}
        close={() => setOpenViewInRoom(false)}
        currentSlide={currentSlide}
      />
    </div>
  );
}

export default DesktopViewinRoom;
