import React, { useState, useEffect } from "react";
import moon from "./image/moon.png";
function HeroSectionDesktop() {
  const [, setTimer] = useState(timestampToDHMS());

  function timestampToDHMS() {
    var date = new Date("28 Jun 2023 12:00");
    var timestamp = date.getTime();
    const timeLeft = timestamp - Date.now();
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        const updatedTimer = { ...prevTimer };
        if (updatedTimer.seconds === 0) {
          if (updatedTimer.minutes === 0) {
            if (updatedTimer.hours === 0) {
              if (updatedTimer.days === 0) {
                clearInterval(interval); // Timer reached 0, stop the interval
              } else {
                updatedTimer.days--;
                updatedTimer.hours = 23;
                updatedTimer.minutes = 59;
                updatedTimer.seconds = 59;
              }
            } else {
              updatedTimer.hours--;
              updatedTimer.minutes = 59;
              updatedTimer.seconds = 59;
            }
          } else {
            updatedTimer.minutes--;
            updatedTimer.seconds = 59;
          }
        } else {
          updatedTimer.seconds--;
        }
        return updatedTimer;
      });
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <div className="center_hero_logo">
      <h1 className="desktop_hero_text">
        <i>Unleash</i> your <br />
        <span className="mobile_hero_text_art">Art Gallery's</span> <br />
        full potential
      </h1>

      <br />
      <p className="hero_logo_under_text">
      Join now and start selling to a global audience on <br />
        the{" "}
        <span style={{ color: "#fff", fontWeight: "800", fontSize: "18px" }}>
          most innovative art platform in the world
        </span>
      </p>
      <br />
      <a href="form" className="myButton_head_Desktop">
        Request Access
      </a>

      <br />
      <br />

      <p className="hero_logo_under_text">
        Galleries from{" "}
        <span style={{ color: "#ffb500", fontWeight: "800" }}>
          42 countries
        </span>{" "}
        have already joined Suarte!
      </p>
      <div className="">
        <img
          src={moon}
          style={{ width: "4%" }}
          className="vert-move moon_hover_desk"
          alt="moon"
          data-robots="noindex"
        />
      </div>
      <div className="Desktop_amr_back">
        <br /> <br />
        <br /> <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSectionDesktop;
