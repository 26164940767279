import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";

export default function App() {
  return (
    <MDBContainer fluid className="">
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody className="">
              <MDBCarousel interval={5000000000000000} showControls dark>
                <MDBCarouselInner className="">
                  <MDBCarouselItem className="active back_card ">
                    <MDBRow className="d-flex justify-content-center  ">
                      <MDBCol lg="10" xl="8">
                        <MDBRow>
                          <MDBCol
                            md="12"
                            lg="12"
                            xl="12"
                            sm="7"
                            className="text-center text-lg-start mx-auto mx-lg-0"
                          >
                            <h4 className="mb-4 head_slider_suarta">
                              SUARTEWORLD
                            </h4>
                            <p className="mb-0 pb-3 para_slider_suarta">
                              An{" "}
                              <span
                                style={{ fontWeight: "700", color: "#FF7900" }}
                              >
                                immersive{" "}
                              </span>{" "}
                              <span style={{ fontWeight: "700" }}>
                                experience
                              </span>{" "}
                              that redefines and personalizes the way users
                              discover and interact with artworks
                            </p>
                            <div className="">
                              {" "}
                              <img
                                src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/4663223d-af46-4262-532b-12ad018fe800/w=400"
                                style={{ width: "50%" }}
                                className="image_world_align"
                                alt="suarta_slider_mobile_1"
                                data-robots="noindex"
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCarouselItem>

                  <MDBCarouselItem>
                    <MDBRow className="d-flex justify-content-center">
                      <MDBCol lg="10" xl="8">
                        <MDBRow>
                          <MDBCol
                            md="12"
                            lg="12"
                            xl="12"
                            className="text-center text-lg-start mx-auto mx-lg-0"
                          >
                            <h4 className="mb-4 head_slider_suarta">
                              RANKINGS
                            </h4>
                            <p className="mb-0 pb-3 para_slider_suarta">
                              <span style={{ fontWeight: "700" }}>
                                Dynamic leaderboards
                              </span>{" "}
                              that show{" "}
                              <span style={{ fontStyle: "italic" }}>
                                la crème de la crème
                              </span>
                              . Discover the most liked{" "}
                              <span
                                style={{ fontWeight: "700", color: "#FF7900" }}
                              >
                                artworks
                              </span>
                              , inspiring{" "}
                              <span
                                style={{ fontWeight: "700", color: "#FF7900" }}
                              >
                                artists
                              </span>{" "}
                              or visited{" "}
                              <span
                                style={{ fontWeight: "700", color: "#FF7900" }}
                              >
                                galleries
                              </span>{" "}
                              across different time periods. Will you rise to
                              the top?
                            </p>{" "}
                            <div className="">
                              {" "}
                              <img
                                src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/86ba20b5-74ea-48c8-50a5-57024167cd00/w=400"
                                style={{ width: "50%" }}
                                className="image_world_align"
                                alt="suarta_slider_mobile_2"
                                data-robots="noindex"
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCarouselItem>

                  <MDBCarouselItem>
                    <MDBRow className="d-flex justify-content-center">
                      <MDBCol lg="10" xl="8">
                        <MDBRow>
                          <MDBCol
                            md="12"
                            lg="12"
                            xl="12"
                            className="text-center text-lg-start mx-auto mx-lg-0"
                          >
                            <h4 className="mb-4 head_slider_suarta">
                              ARTLISTS
                            </h4>
                            <p className="mb-0 pb-3 para_slider_suarta">
                              <span style={{ fontWeight: "700" }}>
                                Curate and share
                              </span>{" "}
                              your own collections. Click{" "}
                              <span
                                style={{ fontWeight: "700", color: "#FF7900" }}
                              >
                                play
                              </span>
                              , set a timer and enjoy watching the artworks
                              flow. Display any artlist at home using Suarte on
                              the TV.
                            </p>
                            <div className="">
                              {" "}
                              <img
                                src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/3abf9347-36e3-485d-0642-d8520539aa00/w=400"
                                style={{ width: "50%" }}
                                className="image_world_align"
                                alt="suarta_slider_mobile_3"
                                data-robots="noindex"
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCarouselItem>

                  <MDBCarouselItem>
                    <MDBRow className="d-flex justify-content-center">
                      <MDBCol lg="10" xl="8">
                        <MDBRow>
                          <MDBCol
                            md="12"
                            lg="12"
                            xl="12"
                            className="text-center text-lg-start mx-auto mx-lg-0"
                          >
                            <h4 className="mb-4 head_slider_suarta">
                              DATA AND INSIGHTS
                            </h4>
                            <p className="mb-0 pb-3 para_slider_suarta">
                              Unlock the power of{" "}
                              <span style={{ fontWeight: "700" }}>data</span>.
                              Gain a deeper understanding of how your gallery,
                              artists and artworks are performing on the
                              platform through in-depth{" "}
                              <span
                                style={{ fontWeight: "700", color: "#FF7900" }}
                              >
                                data analysis
                              </span>
                              .
                            </p>
                            <div className="">
                              {" "}
                              <img
                                src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/ed8e7f51-7074-4f8a-bf26-d9b8cb75ef00/w=400"
                                style={{ width: "50%" }}
                                className="image_world_align"
                                alt="suarta_slider_mobile_4"
                                data-robots="noindex"
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCarouselItem>
                </MDBCarouselInner>
              </MDBCarousel>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
