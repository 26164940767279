import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AccordionItem({ title, content }) {
  return (
    <div
      className="container"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Accordion style={{ backgroundColor: "#000", width: "70%" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "#D000C5",
                fontWeight: "900",
                fontSize: "40px",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              color: "#fff",
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            style={{
              color: "#fff",
              fontWeight: "300",
              fontSize: "17px",
              whiteSpace: "pre-line",
              textAlign: "justify",
            }}
          >
            {content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function Desktop_LEARNTHEESSENTIALS() {
  // const handleShowMore = () => {
  //   setShowMore(!showMore);
  // };

  const accordionItems = [
    {
      title: "What's Suarte and how can it benefit my Gallery?",
      content:
        "Suarte is a game-changer that redefines how you engage with art collectors worldwide. This innovative platform breathes fresh air into the art world, fostering community-driven growth and bringing the art world closer than ever before.\n\nSuarte unites Galleries, their Represented Artists, and Art Lovers & Collectors from across the globe. With features such as personalized profiles, insightful data analytics, and easy inventory management, Suarte offers unique benefits and opportunities to enhance visibility, drive sales, and grow your presence in the art world.",
    },
    {
      title: "Disruptive Social Features",
      content:
        "Suarte is bringing art to life like never before by introducing social features designed to not only learn about each collector's preferences but also guide them in their personal art journey. \n\nThe platform provides an immersive experience where you can explore, interact, and grow your art knowledge. Through Suarte, we're turning the process of discovering art into a personalized, enriching journey, and empowering users to not just discover but to curate their own unique taste in art.\n",
    },
    {
      title: "What are the fees involved?",
      content:
        "Suarte is free to use, and there are no additional or subscription costs.  \n\nThere is a 17% commission fee on every original artwork sold, with a bonification system based on volume coming soon. VAT is applied automatically, and Suarte utilizes Stripe for all payments.",
    },
    {
      title: "Who can sell on the platform? Suarte’s Gallery-Artist Dynamic!",
      content:
        'On Suarte, only Galleries with physical spaces can sell. The Artist\'s involvement varies depending on the inventory status of the artwork: either "Work of Artist" or "Gallery Owned".\n\nFor "Work of Artist" artworks, Artists can claim their profiles once a work has been uploaded and accepted and enjoy personalized features. This unique structure encourages harmonious, effective collaboration between Galleries and Artists, with the objective of driving greater success for both parties.\n \nFor "Gallery owned" artworks, Galleries can opt not to include the Artist’s email, and in this case, no information will be disclosed or sent to the Artist, including emails, insights, or sales reports. \n\nIf the Artist already has a profile inside Suarte, the artwork will seamlessly integrate into the existing profile.\n',
    },
    {
      title: "Can I change the price of my artworks on Suarte?",
      content:
        'Galleries are allowed to adjust the price of your artwork twice during the contract length, at any time. However, the artwork price cannot be reduced to less than 30% of the initial price when uploaded.\n\nNote that all artworks must be available for purchase through the "Buy Now" option. \n',
    },
    {
      title: "Passive Income Opportunities: Limited Editions & Referral System",
      content:
        "Limited editions- Museum quality prints produced and distributed by Suarte. Limited Editions give galleries and their represented artists the opportunity to start receiving passive income and increase their exposure.\n\nSuarte creates 250 Limited Editions in up to three sizes, making the art buying experience more accessible.\n\nReferral system- Galleries can start earning 1% of the purchase price for all originals and Limited Editions purchased by their referrals in Suarte. \n\nShare or Post your gallery's unique link or QR code to get new referrals. There's no limit on the number of referrals a gallery can make!\n",
    },
    {
      title: "Closed Mode for Galleries",
      content:
        'Suarte\'s "Closed Mode" feature allows galleries to take a break from selling for up to 45 days per year. Your profile and artworks remain visible, but buyers cannot make purchases. Instead, they can opt to receive a notification when the artwork is available again. You can customize a message to be displayed during this period.',
    },
  ];

  return (
    <>
      <h1 className="head_learn" id="essentials">
        LEARN THE ESSENTIALS
      </h1>
      <br />
      {accordionItems.slice(0, accordionItems.length).map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
      {/* <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button onClick={handleShowMore} className="less_more_button">
          {showMore ? "View Less" : "View More"}
        </Button>
      </div> */}
      <br />
      <br />
      <br />

      <div className="container center_hero_logo">
        <a href="form" className="myButton_head_Desktop_Learn">
          Be part!
        </a>
      </div>

      <br />
      {/* <p className="learn_under">
        Enjoy the first three artworks commission-free and without <br/> any additional costs.{" "}
        <span style={{ color: "#D000C5", fontWeight: "800" }}>
          Only valid during prelaunch.
        </span>
      </p> */}
    </>
  );
}

export default Desktop_LEARNTHEESSENTIALS;
